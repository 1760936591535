/* eslint-disable prefer-destructuring */
/* eslint-disable func-names */
/**
 *
 * module helper
 *
 */
import ModuleModel from 'models/moduleModel';
import SectionModel from 'models/sectionModel';
import StemModel from 'models/stemModel';
import StimulusModel from 'models/stimulusModel';
import ItemModel from 'models/itemModel';
import { ExamMode } from 'enumerations/ExamMode';
import { isNullOrUndefined } from 'util';
import { parseDateString } from 'helpers/timeHelper';
import { TITLE_TEST_PREP_LIBRARY } from 'containers/MasterPage/constants';
import AnswerOptionModel from '../models/answerOptionModel';
import { mergeModuleState } from './persistence/examManagementHelper';
import { getUserDetails, getUserId } from './userHelper';
import { setUpExamOptions } from './examHelper';
import { ModuleType } from '../enumerations/ModuleType';
import {
  EXAM_TEST_COMPLETE_PATH,
  TEST_COMPLETE_PATH,
} from '../containers/App/constants';

/**
 * Get path to test complete page
 *
 * @param  {object} testInstance The test instance
 *
 */
export function getTestCompletePath(instance) {
  let testCompletePath = instance?.module?.options?.testCompletePath;
  if (isNullOrUndefined(testCompletePath)) {
    if (isRealExam(instance?.module)) {
      testCompletePath = `${EXAM_TEST_COMPLETE_PATH}/`;
    } else {
      testCompletePath = `${TEST_COMPLETE_PATH}/`;
    }
  }
  return `${testCompletePath}${instance.testInstanceId}`;
}

export function isRealExam(module) {
  return module?.options?.isRealExam || module?.options?.isRealExamMode;
}

export function getTabTitle(instance) {
  let title = instance?.module?.options?.title;
  if (isNullOrUndefined(title)) {
    title = TITLE_TEST_PREP_LIBRARY;
  }
  return title;
}
/**
 * Determine if lsat flex
 *
 * @param  {object} testInstance The test instance
 *
 */
export function isLSATFlexInstance(testInstance) {
  if (!isNullOrUndefined(testInstance)) {
    return isLSATFlexModule(testInstance.module);
  }
  return false;
}

export function isLSATFlexModule(module) {
  if (!isNullOrUndefined(module)) {
    return module?.options?.isLsatFlex === true;
  }
  return false;
}

export function isWritingSection(section) {
  if (isNullOrUndefined(section)) {
    return false;
  }
  return section?.options?.sectionType === ModuleType.Writing;
}

export function isScoredWritingSection(section) {
  if (isNullOrUndefined(section)) {
    return false;
  }
  return section?.options?.sectionType === ModuleType.WritingWithPerspectives;
}

export function isWritingSectionByInstance(selectedInstance) {
  if (isNullOrUndefined(selectedInstance)) {
    return false;
  }
  return selectedInstance?.options?.moduleType === ModuleType.Writing;
}

export function isScoredWritingSectionByInstance(selectedInstance) {
  if (isNullOrUndefined(selectedInstance)) {
    return false;
  }
  return (
    selectedInstance?.options?.moduleType === ModuleType.WritingWithPerspectives
  );
}

export function getTotalAnsweredInSection(section) {
  if (!section) return 0;
  return section?.items?.filter(i => {
    if (i.writingText && i.writingText.length > 0) return true;
    return i.isAnswered;
  }).length;
}
export function disableBackButton(instance) {
  if (!isNullOrUndefined(instance)) {
    return instance?.module?.options?.disableBackButton === true;
  }
  return false;
}

export function showFullScreen(instance) {
  if (!isNullOrUndefined(instance)) {
    return instance?.module?.options?.showFullScreen === true;
  }
  return false;
}

export function hasStartStopBreaks(instance) {
  if (!isNullOrUndefined(instance)) {
    return instance?.module?.options?.hasStartStopBreaks === true;
  }
  return false;
}

/**
 * Determine if all sections are completed returns true only
 * if all sections are isCompleted == true
 *
 * @param  {object} testInstance The test instance
 *
 */

export function isAllSectionsCompleted(testInstance) {
  let allSectionsCompleted = true;

  if (
    isNullOrUndefined(testInstance) ||
    isNullOrUndefined(testInstance.module) ||
    isNullOrUndefined(testInstance.module.sections)
  ) {
    return true;
  }

  // check for completed sections
  Array.prototype.forEach.call(testInstance.module.sections, item => {
    if (!item.isCompleted) {
      allSectionsCompleted = false;
    }
  });

  return allSectionsCompleted;
}

export function isSectionCompleted(testInstance, sectionId) {
  if (
    isNullOrUndefined(testInstance) ||
    isNullOrUndefined(testInstance.module) ||
    isNullOrUndefined(testInstance.module.sections)
  ) {
    return true;
  }

  const section = testInstance.module.sections.filter(
    s => s.sectionName === sectionId,
  )[0];

  return section?.isCompleted;
}

/**
 * Retrieve a module from modules
 *
 * @param  {array} modules The modules data
 * @param  {string} moduleId The module identifier
 *
 */
export function getInstance(instances, testInstanceId) {
  let selectedInstance = null;
  if (
    !isNullOrUndefined(instances) &&
    instances.length > 0 &&
    !isNullOrUndefined(testInstanceId)
  ) {
    const instanceFound = instances.filter(
      m => m.testInstanceId === testInstanceId,
    );
    if (instanceFound.length > 0) {
      selectedInstance = instanceFound[0];
    }
  }
  return selectedInstance;
}

/**
 * Retrieve module and section from modules
 *
 * @param  {array} modules The modules data
 * @param  {string} moduleId The module identifier
 * @param  {string} sectionId The section identifier
 *
 */
export function getInstanceSection(instances, instanceId, sectionId) {
  const selectedInstance = getInstance(instances, instanceId);
  const section = getSection(selectedInstance, sectionId);
  return { selectedInstance, section };
}

export function getSection(selectedInstance, sectionId) {
  let section = null;
  if (!isNullOrUndefined(selectedInstance)) {
    if (!isNullOrUndefined(sectionId)) {
      const sectionFound = selectedInstance.module.sections.filter(
        s => s.title === sectionId,
      );
      if (sectionFound.length > 0) {
        section = sectionFound[0];
      }
    }
  }
  return section;
}

/**
 * Retrieve module and section from modules
 *
 * @param  {array} modules The modules data
 * @param  {string} moduleId The module identifier
 * @param  {string} sectionId The section identifier
 *
 */
export function getInstanceSectionByFormId(instances, instanceId, sectionId) {
  const selectedInstance = getInstance(instances, instanceId);
  let section = null;
  if (!isNullOrUndefined(selectedInstance)) {
    if (!isNullOrUndefined(sectionId)) {
      const sectionFound = selectedInstance.module.sections.filter(
        s => s.formId === sectionId,
      );
      if (sectionFound.length > 0) {
        section = sectionFound[0];
      }
    }
  }
  return { selectedInstance, section };
}

/**
 * Convert modules from the API format to local module format
 *
 * @param  {array} modules The module data
 * @param  {array} existingModules The existing modules
 *
 */
export function convertModules(modules, existingModules) {
  const convertedModules = [];
  if (!isNullOrUndefined(modules) && modules.length > 0) {
    modules.forEach(function(module) {
      let convertedModule = null;
      const existingModule = existingModules.filter(
        m => m.moduleId === module.id,
      );
      if (existingModule.length > 0) {
        convertedModule = existingModule[0];
      } else {
        convertedModule = convertModule(module);
      }
      convertedModules.push(convertedModule);
    });
  }
  return convertedModules;
}

/**
 * Convert module from the API format to local module format
 *
 * @param  {ModuleModel} module The module data
 *
 */
function convertModule(module) {
  let convertedModule = null;
  if (!isNullOrUndefined(module)) {
    convertedModule = new ModuleModel(
      module.moduleId,
      module.moduleName,
      convertSections(module.sections),
      module.moduleDescription,
      module.adminDate,
      convertHistory(module.moduleHistory),
      undefined,
      undefined,
      module.options,
      module.directions,
      module.browser,
      module.productName,
      module.moduleType,
    );
  }
  if (!convertedModule.options || !convertedModule.options.isRealExamMode) {
    setUpExamOptions(
      convertedModule,
      getUserDetails(),
      undefined,
      undefined,
      module.moduleType,
    );
  }
  return convertedModule;
}

/**
 * Convert module from the API format to local module format
 *
 * @param  {ModuleModel} module The module data
 * @param  {array} modules The module list
 *
 */
export function insertInstance(
  testInstanceId,
  examType,
  module,
  instances,
  state,
  summary,
  isExistingInstance,
  launchDetails,
  testInstanceRemoteStatus,
) {
  let convertedModule = null;
  if (!isNullOrUndefined(module)) {
    convertedModule = new ModuleModel(
      module.id,
      module.moduleName,
      convertSections(
        module.sections,
        module.examMode,
        module.customTime,
        module.moduleType,
      ),
      module.description,
      module.adminDate,
      convertHistory(module.history),
      module.examMode,
      module.customTime,
      module.options,
      module.directions,
      module.browser,
      module.allowSubmitEarly,
      module.moduleType,
    );
    convertedModule = mergeModuleState(convertedModule, state);
    if (!convertedModule.options || !convertedModule.options.isRealExamMode) {
      setUpExamOptions(
        convertedModule,
        getUserDetails(),
        undefined,
        undefined,
        module.moduleType,
      );
    }
    const existingInstance = instances.filter(
      m => m.testInstanceId === testInstanceId,
    );
    if (existingInstance.length > 0) {
      remove(instances, existingInstance[0]);
    }
    if (!isNullOrUndefined(isExistingInstance) && isExistingInstance) {
      convertedModule.isReloaded = true;
    }
    instances.push({
      testInstanceId,
      examType,
      module: convertedModule,
      summary,
      launchDetails,
      testInstanceRemoteStatus,
    });
  }
  return instances;
}

/**
 * remove an item from an array
 * @param {array} array the array
 * @param {object} item the item to be removed
 */
export function remove(array, item) {
  const index = array.indexOf(item);
  if (index > -1) {
    array.splice(index, 1);
  }
}

/**
 * Convert sections from the API format to local section format
 *
 * @param  {array} sections The sections data
 *
 */
function convertSections(sections, examMode, customTime, moduleType) {
  const convertedSections = [];
  if (!isNullOrUndefined(sections) && sections.length > 0) {
    sections.forEach(function(section) {
      const convertedSection = new SectionModel();
      convertedSection.formId = section.sectionId;
      convertedSection.sectionOrder = section.sectionOrder;
      convertedSection.title = `Section ${section.sectionOrder}`;
      convertedSection.directions = section.directions;
      convertedSection.hasGroupItems = section.hasGroupedItems;
      const itemInfo = convertItems(section.items);
      convertedSection.items = itemInfo.items;
      convertedSection.stimulus = itemInfo.stimulus;
      convertedSection.stems = itemInfo.stems;
      convertedSection.acknowledgements = section.acknowledgements;
      convertedSection.sectionName = section.sectionName;
      convertedSection.numberOfQuestions = section.itemCount;
      convertedSection.totalSectionTime = section.totalSectionTime;
      convertedSection.options = section.options;
      convertedSection.breakType = section.breakType;
      convertedSection.isVariableSection = section.isVariableSection;
      convertedSection.moduleType = moduleType;
      // Default time
      if (isNullOrUndefined(convertedSection.totalSectionTime)) {
        convertedSection.totalSectionTime = 300000;
      }
      if (
        !isNullOrUndefined(examMode) &&
        !isNullOrUndefined(customTime) &&
        (examMode === ExamMode.ExamTime || examMode === ExamMode.SelfPaced)
      ) {
        convertedSection.totalSectionTime = customTime * 60 * 1000;
      }
      if (section?.options?.sectionTime) {
        convertedSection.totalSectionTime =
          section.options.sectionTime * 60 * 1000;
      }
      convertedSection.timeRemaining = convertedSection.totalSectionTime;

      if (section?.options?.analysisSectionTime) {
        convertedSection.totalAnalysisSectionTime =
          section.options.analysisSectionTime * 60 * 1000;
        convertedSection.analysisTimeRemaining =
          convertedSection.totalAnalysisSectionTime;
      }

      if (!isNullOrUndefined(section.isPassageSection)) {
        convertedSection.passageSection = section.isPassageSection;
      }
      if (!isNullOrUndefined(section.isCompleted)) {
        convertedSection.isCompleted = section.isCompleted;
      }
      convertedSections.push(convertedSection);
    });
  }
  return convertedSections;
}

function convertHistory(moduleHistory) {
  const convertedHistory = [];
  if (!isNullOrUndefined(moduleHistory) && moduleHistory.length > 0) {
    moduleHistory.forEach(function(history) {
      const convertedItem = {};
      convertedItem.isCompleted = history.isCompleted;
      convertedItem.moduleAdminDate = parseDateString(history.moduleAdminDate);
      convertedItem.moduleDescription = history.moduleDescription;
      convertedItem.moduleId = history.moduleId;
      convertedItem.moduleName = history.moduleName;
      convertedItem.sectionResults = history.sectionResults;
      convertedItem.startDate = parseDateString(history.startDate);
      convertedItem.totalScore = history.totalScore;
      convertedItem.convertedScore = history.convertedScore;
      convertedItem.totalTimeTaken = history.totalTimeTaken;
      convertedItem.testInstanceId = history.testInstanceId;
      convertedHistory.push(convertedItem);
    });
  }
  return convertedHistory;
}
/**
 * Convert items from the API format to local item format
 *
 * @param  {array} items The items data
 *
 */
function convertItems(items) {
  const convertedItems = [];
  const convertedStimulus = [];
  const convertedStems = [];
  if (!isNullOrUndefined(items) && items.length > 0) {
    items.forEach(function(item) {
      const convertedItem = new ItemModel();
      convertedItem.itemNumber = item.itemPosition;
      convertedItem.elementId = item.itemId;
      populateStimulusStem(item, convertedStimulus, convertedStems);
      convertedItem.stimulusId = item.groupId;
      convertedItem.stemId = item.itemId;
      convertedItem.answerOptions = convertOptions(item.options);
      convertedItem.correctAnswer = item.correctAnswer;
      convertedItem.hintGroup = item.hintGroup;
      convertedItem.readExplanation = item.readExplanation;
      convertedItem.itemSubTypeTextBasedExplanation =
        item.itemSubTypeTextBasedExplanation;
      convertedItem.itemSubTypeVideoExplanation =
        item.itemSubTypeVideoExplanation;
      convertedItem.skill = item.skill;
      convertedItem.difficultLevelName = item.difficultLevelName;
      convertedItem.totalTimeTaken = item.totalTimeTaken;
      convertedItem.displayType = item.displayType;
      convertedItem.totalTimeTaken = item.totalTimeTaken;
      convertedItems.push(convertedItem);
    });
  }
  return {
    items: convertedItems,
    stimulus: convertedStimulus,
    stems: convertedStems,
  };
}

/**
 * Convert options from the API format to local option format
 *
 * @param  {array} items The items data
 *
 */
function convertOptions(options) {
  const convertedOptions = [];
  if (!isNullOrUndefined(options) && options.length > 0) {
    options.forEach(function(option) {
      const convertedOption = new AnswerOptionModel();
      convertedOption.text = option.optionContent;
      convertedOption.letter = option.optionLetter;
      convertedOptions.push(convertedOption);
    });
  }
  return convertedOptions;
}

/**
 * Populate the stems and stimulus array from item
 *
 * @param  {Item} item The item data
 * @param  {array} convertedStimulus The converted stimulus array
 * @param  {array} convertedStems The converted stem array
 *
 */
function populateStimulusStem(item, convertedStimulus, convertedStems) {
  const stem = new StemModel();
  stem.stemId = item.itemId;
  stem.text = item.stemText;
  convertedStems.push(stem);
  const existingStimulus = convertedStimulus.filter(
    s => s.stimulusId === item.groupId,
  );
  if (existingStimulus.length === 0) {
    const stimulus = new StimulusModel();
    stimulus.stimulusId = item.groupId;
    stimulus.text = item.stimulusText;
    convertedStimulus.push(stimulus);
  }
}

/**
 * Populate the stems and stimulus array from item
 *
 * @param  {array} modules The list of modules
 * @param  {SectionModel} section The section data
 * @param  {string} moduleId The module id
 * @param  {string} sectionId The section id
 * @param  {bool} initSaga The init Saga flag
 * @param  {func} onGetModule The get module function
 * @param  {func} onSetSection The set section function
 *
 */
export function getInstanceAndSetSection(
  instances,
  section,
  instanceId,
  sectionId,
  initSaga,
  onGetInstance,
  onSetSection,
  onSectionLoaded,
) {
  const result = getInstanceSection(instances, instanceId, sectionId);
  const selectedSection = result.section;
  const selectedInstance = result.selectedInstance;
  if (isNullOrUndefined(selectedSection) && initSaga) {
    onGetInstance({ testInstanceId: instanceId, userId: getUserId() });
  }
  if (!isNullOrUndefined(selectedSection)) {
    onSetSection(selectedSection, instanceId);

    if (!isNullOrUndefined(onSectionLoaded)) {
      onSectionLoaded(selectedSection);
    }
  }
  return { selectedInstance, selectedSection };
}

/**
 * To verify all sections in module are completed or not.
 *
 * @param {object} module object
 */
export function hasAllSectionsCompleted(module) {
  if (!isNullOrUndefined(module)) {
    if (module.moduleType === ModuleType.DrillSet) {
      // DrillSet will have only one section
      return module.sections.some(section => section.isCompleted === true);
    }
    const hasNotCompletedSection = module.sections.some(
      section => section.isCompleted === false,
    );
    return hasNotCompletedSection === false;
  }

  return false;
}

export function getSectionBreakInfo(module, sectionId) {
  let selectedSection = null;
  let nextSection = null;
  if (module !== null) {
    selectedSection = module.sections.find(
      section => section.title === sectionId,
    );
    const pausedSection = module.sections.find(
      section => section.isPaused && section.formId === selectedSection?.formId,
    );

    if (selectedSection === null) {
      nextSection = module?.sections[0];
    } else if (!isNullOrUndefined(pausedSection)) {
      nextSection = pausedSection;
    } else if (selectedSection.isCompleted === false) {
      const selectedIndex = module.sections.indexOf(selectedSection);
      if (selectedIndex === module.sections.length - 1) {
        nextSection = selectedSection;
      } else {
        nextSection = module.sections[selectedIndex + 1];
      }
    } else {
      const notCompletedSection = module.sections.find(
        section => section.isCompleted === false,
      );
      if (!isNullOrUndefined(notCompletedSection)) {
        nextSection = notCompletedSection;
      } else {
        nextSection = module?.sections[0];
      }
    }
  }

  return { selectedSection, nextSection };
}

/* eslint-disable react/jsx-curly-brace-presence */
/**
 *
 * SectionResult
 *
 */

import React, { useState, useEffect } from 'react';
import FontAwesome from 'react-fontawesome';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from 'react-intl';
import './index.css';
import { isNullOrUndefined } from 'util';
import history from '../../utils/history';
import {
  calculateRawScore,
  calculatePinnedQuestions,
} from '../../helpers/rawScoreHelper';
import { getInstanceById } from '../../helpers/persistence/common/repoHelper';
import {
  millisToHoursMinsTextShortVersion,
  millisToHoursMinsTextLongVersion,
} from '../../helpers/timeHelper';
import { isRealExam } from '../../helpers/moduleHelper';
import { sortArrayOfObjectsByKey } from '../../helpers/commonHelper';
import {
  QUESTION_PATH,
  EXAM_QUESTION_PATH,
} from '../../containers/App/constants';
import messages from './messages';
import { ModuleType } from '../../enumerations/ModuleType';
import { ITEM_NUMBER, DIFFICULTY_LEVEL, SKILL, TIME_SPENT } from './constants';
import dashIcon from '../../images/dash_icon.svg';

function SectionResult({
  section,
  sectionFromState,
  testInstanceId,
  hideReviewButton,
  isWriting,
  onChangeSelection,
  intl,
}) {
  const [instance, setInstance] = useState([]);
  const [sectionItem, setSectionItem] = useState([]);
  const [sortedOrder, setSortedOrder] = useState('');
  const [sortedKey, setSortedKey] = useState('');

  useEffect(() => {
    setInstance(getInstanceById(testInstanceId));
    if (!isNullOrUndefined(section) && !isNullOrUndefined(section?.items)) {
      const newItems = [...section?.items];
      const sortedList = sortArrayOfObjectsByKey(newItems, ITEM_NUMBER, 'asc');
      setSectionItem(sortedList);
      setSortedKey(ITEM_NUMBER);
      setSortedOrder('asc');
    }
  }, [section]);

  const textCenter = 'text-center';
  const textRight = 'text-right';
  const isDrillSet = section?.moduleType === ModuleType.DrillSet;
  const isAdditionalPracticeSet =
    section?.moduleType === ModuleType.DrillSetAdditional;

  let orderType = sortedOrder;

  const isPrepTest =
    !section?.moduleType ||
    section.moduleType === ModuleType.MultipleChoice ||
    section.moduleType === ModuleType.Writing ||
    section.moduleType === ModuleType.WritingWithPerspectives ||
    section.moduleType === ModuleType.NextGenPrepTest;

  const isPrepAvailable =
    section?.moduleType &&
    Object.values(ModuleType).includes(section?.moduleType);

  if (sortedOrder !== '') {
    orderType = sortedOrder === 'asc' ? 'Descending' : 'Ascending';
  }

  function getSelectedAnswerTag(selectedAnswer) {
    if (selectedAnswer && selectedAnswer === '--') {
      return (
        <td className="reportTextAlignment section-result-response">
          <span className="hidden">
            <FormattedMessage {...messages.noResponse} />
          </span>
          <FontAwesome name="noAnswerSelection" className="fa fa-ban" />
        </td>
      );
    }
    return (
      <td className="reportTextAlignment section-result-response">
        {selectedAnswer}
      </td>
    );
  }

  const getFontAwesome = isCorrect => {
    if (isCorrect) {
      return (
        <FontAwesome name="check" className="section-result-right-answer" />
      );
    }
    return (
      <FontAwesome
        name="selectedAnswer"
        className="fa fa-times-circle section-result-wrong-answer"
      />
    );
  };

  const getReviewFlag = flagged => {
    if (flagged) {
      return (
        <td className={textRight}>
          <span className="hidden">
            <FormattedMessage {...messages.flagged} />
          </span>
          <FontAwesome name="flag" className="section-result-flagged" />
        </td>
      );
    }

    return (
      <td className="reportTextAlignment">
        <span className="hidden">
          <FormattedMessage {...messages.notFlagged} />
        </span>
      </td>
    );
  };

  const getDrillSetResponse = (selectedAnswer, isAnsCorrect) => {
    if (selectedAnswer && selectedAnswer === '--') {
      return (
        <td className="reportTextAlignment section-result-response">
          <span className="hidden">
            <FormattedMessage {...messages.noResponse} />
          </span>
          <FontAwesome name="noAnswerSelection" className="fa fa-ban" />
        </td>
      );
    }
    if (isAnsCorrect) {
      return (
        <td className="reportTextAlignment section-result-response">
          <span className="hidden">
            <FormattedMessage {...messages.correct} />
          </span>
          <FontAwesome name="check" className="section-result-right-answer" />
        </td>
      );
    }
    return (
      <td className="reportTextAlignment section-result-response">
        <span className="hidden">
          <FormattedMessage {...messages.incorrect} />
        </span>
        {selectedAnswer}
      </td>
    );
  };

  const convertToMinutesAndSeconds = totalSeconds => {
    if (typeof totalSeconds !== 'number' || Number.isNaN(totalSeconds)) {
      return '--';
    }
    const convertedSeconds = Math.floor(totalSeconds);
    const hours = Math.floor(convertedSeconds / 3600);
    const minutes = Math.floor((convertedSeconds % 3600) / 60);
    const formattedSeconds = convertedSeconds % 60;
    const seconds =
      formattedSeconds < 10 ? `0${formattedSeconds}` : `${formattedSeconds}`;
    if (hours > 0) {
      return (
        <>
          <span className="sr-only">
            <FormattedMessage
              {...messages.timeSpentInHoursSrText}
              values={{
                hours,
                min: minutes,
                sec: seconds,
              }}
            />
          </span>
          <span aria-hidden="true">
            <FormattedMessage
              {...messages.timeSpentInHours}
              values={{
                hours,
                min: minutes,
                sec: seconds,
              }}
            />
          </span>
        </>
      );
    }
    if (minutes > 0) {
      return (
        <>
          <span className="sr-only">
            <FormattedMessage
              {...messages.timeSpentInMinutesSrText}
              values={{
                min: minutes,
                sec: seconds,
              }}
            />
          </span>
          <span aria-hidden="true">
            <FormattedMessage
              {...messages.timeSpentInMinutes}
              values={{
                min: minutes,
                sec: seconds,
              }}
            />
          </span>
        </>
      );
    }
    return (
      <>
        <span className="sr-only">
          <FormattedMessage
            {...messages.timeSpentInSecondsSrText}
            values={{
              sec: seconds,
            }}
          />
        </span>
        <span aria-hidden="true">
          <FormattedMessage
            {...messages.timeSpentInSeconds}
            values={{
              sec: seconds,
            }}
          />
        </span>
      </>
    );
  };

  const getTableContent = (item, offset, startIndex) => {
    const questionNumber = startIndex + offset + 1;
    const selectedAnswer = item.answer ? item.answer : '--';
    const isSelectedAnsCorrect =
      !item.markForReview && selectedAnswer === item.correctAnswer;
    const customClass = !isSelectedAnsCorrect
      ? 'reportTextAlignment'
      : 'reportTextAlignment section-result-answer';
    const isAnsCorrect = selectedAnswer === item.correctAnswer;
    const correctAnswerIsEmpty =
      !item.correctAnswer || item.correctAnswer === '';

    let correctAnswerMessage = '';
    if (correctAnswerIsEmpty) {
      correctAnswerMessage = messages.itemRemoved;
    } else if (isSelectedAnsCorrect) {
      correctAnswerMessage = messages.correct;
    } else {
      correctAnswerMessage = messages.incorrect;
    }

    const correctDisplayType = item.displayType ? (
      item.displayType
    ) : (
      <>
        <span className="hidden">No Data</span>
        <img src={dashIcon} alt="" className="dash-icon" />
      </>
    );
    const correctDifficultLevelName = item.difficultLevelName ? (
      item.difficultLevelName
    ) : (
      <>
        <span className="hidden">No Data</span>
        <img src={dashIcon} alt="" className="dash-icon" />
      </>
    );

    const totalTimeTaken =
      (
        (sectionFromState?.items ?? []).find(
          itemFromState => itemFromState.itemNumber === item.itemNumber,
        ) ?? {}
      ).totalTimeTaken ?? 0;

    return (
      <tr key={`response_${questionNumber}`}>
        <td className="reportTextAlignment">
          {isDrillSet || isAdditionalPracticeSet
            ? item.itemNumber
            : questionNumber}
        </td>
        {(isDrillSet || isAdditionalPracticeSet) &&
          getDrillSetResponse(selectedAnswer, isAnsCorrect)}
        {!isDrillSet && !isAdditionalPracticeSet && (
          <>
            {getReviewFlag(item.markForReview)}
            {getSelectedAnswerTag(selectedAnswer)}

            <td className="reportTextAlignment">
              <span className="hidden">
                <FormattedMessage {...correctAnswerMessage} />
              </span>
              {correctAnswerIsEmpty ? (
                <img src={dashIcon} alt="" className="dash-icon" />
              ) : (
                getFontAwesome(isAnsCorrect)
              )}
            </td>
          </>
        )}
        <td className="reportTextAlignment section-result-answer">
          {correctAnswerIsEmpty ? (
            <>
              <span className="hidden">No Data</span>
              <img src={dashIcon} alt="" className="dash-icon" />
            </>
          ) : (
            item.correctAnswer
          )}
        </td>
        {isDrillSet ? (
          <td className={customClass}>{item.skill}</td>
        ) : (
          isPrepAvailable &&
          !isAdditionalPracticeSet && (
            <td className={customClass}>{correctDisplayType}</td>
          )
        )}
        {isDrillSet ? (
          <td className={customClass}>{item.difficultLevelName}</td>
        ) : (
          isPrepAvailable &&
          !isAdditionalPracticeSet && (
            <td className={customClass}>{correctDifficultLevelName}</td>
          )
        )}

        {(isDrillSet || isPrepTest) && (
          <td className={customClass}>
            {convertToMinutesAndSeconds(totalTimeTaken)}
          </td>
        )}

        {(isDrillSet || isAdditionalPracticeSet) && (
          <td>
            <button
              className="section-result-review-section-button"
              data-testid="review-answer"
              type="button"
              aria-label={`Review Answer ${item.itemNumber}`}
              onClick={() => navigateBack(item.itemNumber)}
            >
              <FormattedMessage {...messages.review} />
            </button>
          </td>
        )}
      </tr>
    );
  };

  const sortItems = itemKey => {
    let newSortOrder = sortedOrder === 'asc' ? 'desc' : 'asc';

    if (sortedKey === itemKey) {
      newSortOrder = sortedOrder === 'asc' ? 'desc' : 'asc';
      setSortedOrder(newSortOrder);
    } else {
      newSortOrder = 'asc';
      setSortedKey(itemKey);
      setSortedOrder(newSortOrder);
    }

    let updatedItems = [...sectionItem];

    if (itemKey === TIME_SPENT) {
      updatedItems = updatedItems.map(item => {
        const matchingItem = sectionFromState.items.find(
          stateItem => stateItem.itemNumber === item.itemNumber,
        );
        return matchingItem
          ? { ...item, totalTimeTaken: matchingItem.totalTimeTaken ?? 0 }
          : item;
      });
    }

    updatedItems = sortArrayOfObjectsByKey(updatedItems, itemKey, newSortOrder);

    setSectionItem([...updatedItems]);
  };

  const sortIcon = sortedOrder === 'asc' ? 'fa-sort-up' : 'fa-sort-down';

  const getAriaContent = (keyName, customKeyName, order) => {
    if (sortedKey === keyName) {
      return intl.formatMessage(
        {
          ...messages.sortingMessage,
        },
        { sortedKey: customKeyName, orderType: order },
      );
    }
    return intl.formatMessage(
      {
        ...messages.defaultSortingMessage,
      },
      { sortedKey: customKeyName, orderType: customKeyName },
    );
  };

  function getResultTablePart(items, lastIndex, startIndex) {
    const tempItems =
      items && lastIndex !== null ? items.slice(startIndex, lastIndex) : [];
    return (
      <table
        className={`table table-condensed table-sm testResults ${
          isPrepAvailable && isAdditionalPracticeSet && !isDrillSet
            ? 'additional-practice-results'
            : ''
        }`}
      >
        <caption className="hidden">Score report</caption>
        <thead>
          <tr>
            <th scope="col" className="reportTextAlignment">
              {isDrillSet || isAdditionalPracticeSet ? (
                <React.Fragment>
                  <button
                    type="button"
                    onClick={() => {
                      sortItems(ITEM_NUMBER);
                    }}
                    aria-label={getAriaContent(
                      ITEM_NUMBER,
                      'Question Number',
                      orderType,
                    )}
                    title={getAriaContent(
                      ITEM_NUMBER,
                      'Question Number',
                      orderType,
                    )}
                    id="questionNumber"
                  >
                    <span className="columnHeaderText">#</span>
                    <FontAwesome
                      name="sort"
                      className={`fa-solid fa-sort ${sortedKey ===
                        ITEM_NUMBER && sortIcon}`}
                    />
                  </button>
                </React.Fragment>
              ) : (
                <span>#</span>
              )}
            </th>

            {!isDrillSet && !isAdditionalPracticeSet && (
              <th scope="col">
                <span className="hidden">
                  <FormattedMessage {...messages.flaggedQuestion} />
                </span>
              </th>
            )}
            <th scope="col" className="reportTextAlignment columnHeaderText">
              <FormattedMessage {...messages.response} />
            </th>
            {!isDrillSet && !isAdditionalPracticeSet && (
              <th scope="col">
                <span className="hidden">
                  <FormattedMessage {...messages.answerStatus} />
                </span>
              </th>
            )}
            <th scope="col" className="reportTextAlignment columnHeaderText">
              <FormattedMessage {...messages.answer} />
            </th>
            {isDrillSet ? (
              <th scope="col" className="reportTextAlignment">
                <button
                  type="button"
                  onClick={() => {
                    sortItems(SKILL);
                  }}
                  aria-label={getAriaContent(SKILL, 'Subtype', orderType)}
                  title={getAriaContent(SKILL, 'Subtype', orderType)}
                  id="subTypeButton"
                >
                  <span className="columnHeaderText">Subtype</span>
                  <FontAwesome
                    name="sort"
                    className={`fa-solid fa-sort ${sortedKey === SKILL &&
                      sortIcon}`}
                  />
                </button>
              </th>
            ) : (
              isPrepAvailable &&
              !isAdditionalPracticeSet && (
                <th scope="col" className="reportTextAlignment">
                  <span className="columnHeaderText">Subtype</span>
                </th>
              )
            )}
            {isDrillSet ? (
              <th scope="col" className="reportTextAlignment">
                <button
                  type="button"
                  onClick={() => {
                    sortItems(DIFFICULTY_LEVEL);
                  }}
                  aria-label={getAriaContent(
                    DIFFICULTY_LEVEL,
                    'Difficulty',
                    orderType,
                  )}
                  title={getAriaContent(
                    DIFFICULTY_LEVEL,
                    'Difficulty',
                    orderType,
                  )}
                  id="difficultyButton"
                >
                  <span className="columnHeaderText">Difficulty</span>
                  <FontAwesome
                    name="sort"
                    className={`fa-solid fa-sort ${sortedKey ===
                      DIFFICULTY_LEVEL && sortIcon}`}
                  />
                </button>
              </th>
            ) : (
              isPrepAvailable &&
              !isAdditionalPracticeSet && (
                <th scope="col" className="reportTextAlignment">
                  <span className="columnHeaderText">Difficulty</span>
                </th>
              )
            )}
            {isDrillSet && (
              <th scope="col" className="reportTextAlignment">
                <button
                  type="button"
                  onClick={() => {
                    sortItems(TIME_SPENT);
                  }}
                  aria-label={getAriaContent(
                    TIME_SPENT,
                    'TimeSpent',
                    orderType,
                  )}
                  title={getAriaContent(TIME_SPENT, 'TimeSpent', orderType)}
                  id="timeSpentButton"
                >
                  <span className="columnHeaderText">Total Question Time</span>
                  <FontAwesome
                    name="sort"
                    className={`fa-solid fa-sort ${sortedKey === TIME_SPENT &&
                      sortIcon}`}
                  />
                </button>
              </th>
            )}
            {isPrepTest && (
              <th scope="col" className="reportTextAlignment">
                <span className="columnHeaderText">Total Question Time</span>
              </th>
            )}
            {(isDrillSet || isAdditionalPracticeSet) && (
              <th scope="col" className="reportTextAlignment columnHeaderText">
                <FormattedMessage {...messages.review} />
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {tempItems &&
            JSON.stringify(tempItems) !== '[{}]' &&
            tempItems.map((item, offset) =>
              getTableContent(item, offset, startIndex),
            )}
        </tbody>
      </table>
    );
  }

  const getAnswersSection = () => (
    <React.Fragment>
      <div className="table-responsive">
        {getResultTablePart(sectionItem, section?.numberOfQuestions, 0)}
      </div>
    </React.Fragment>
  );

  const navigateBack = async (currentIndex = null) => {
    if (
      !isNullOrUndefined(currentIndex) &&
      (isDrillSet || isAdditionalPracticeSet)
    ) {
      await onChangeSelection(currentIndex - 1);
    }
    if (instance) {
      const basePath = isRealExam(instance.module)
        ? EXAM_QUESTION_PATH
        : QUESTION_PATH;

      history.push(`${basePath}/${testInstanceId}/${section?.title}`);
    }
  };

  const reviewForEachQuestion =
    !hideReviewButton && !isWriting && !isDrillSet && !isAdditionalPracticeSet;

  return (
    <div className="section-result-container">
      {!isWriting && !isDrillSet && !isAdditionalPracticeSet && (
        <Row className="pt-4" style={{ height: '10%' }}>
          <Col
            xs={{ size: 4, offset: isWriting === true ? 4 : 2 }}
            className={textCenter}
          >
            <h2 className="section-result-score-time-header">
              <span>Section Time</span>
            </h2>
            <div className="section-result-score-time">
              <span aria-labelledby="timeMinutes" aria-hidden="true">
                {millisToHoursMinsTextShortVersion(
                  section?.totalSectionTime - section?.timeRemaining,
                )}
              </span>
              <span className="hidden" id="timeMinutes">
                {millisToHoursMinsTextLongVersion(
                  section?.totalSectionTime - section?.timeRemaining,
                )}
              </span>
            </div>
          </Col>
          <Col xs={{ size: 3 }} className={textCenter}>
            <h2 className="section-result-score-time-header">
              <span>Raw Score</span>
            </h2>
            <div className="section-result-score-time">
              {sectionItem &&
                // eslint-disable-next-line func-names
                calculateRawScore(section.items)}
              /
              {section.numberOfQuestions -
                calculatePinnedQuestions(section.items)}
            </div>
          </Col>
        </Row>
      )}
      {reviewForEachQuestion && (
        <Row className="pt-4">
          <Col xs="12 text-center">
            <button
              className="section-result-review-section-button"
              type="button"
              onClick={navigateBack}
            >
              {'Review Section'}
            </button>
          </Col>
        </Row>
      )}
      {!isWriting && (
        <div
          className={`${
            isDrillSet || isAdditionalPracticeSet ? 'pt-4' : 'result-wrapper'
          } ${!isPrepAvailable && !isPrepTest ? 'old-prep-test' : ''}`}
        >
          {getAnswersSection()}
        </div>
      )}
    </div>
  );
}

SectionResult.propTypes = {
  section: PropTypes.object,
  sectionFromState: PropTypes.object,
  testInstanceId: PropTypes.string,
  hideReviewButton: PropTypes.bool,
  isWriting: PropTypes.bool,
  onChangeSelection: PropTypes.func,
  intl: intlShape,
};

export default injectIntl(SectionResult);

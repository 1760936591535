import PropTypes from 'prop-types';
import answerOptionModel from './answerOptionModel';

/**
 * Section model
 * @class
 */
class ItemModel {
  constructor() {
    /**
     * @property {number} itemNumber The item number
     */
    this.itemNumber = 0;

    /**
     * @property {bool} markForReview Determines whether the item is marked for review
     */
    this.markForReview = false;

    /**
     * @property {bool} isAnswered Determines whether the item is answered or not
     */
    this.isAnswered = false;

    /**
     * @property {string} answer The answer
     */
    this.answer = null;

    /**
     * @property {string} stimulusId The stimulus id
     */
    this.stimulusId = null;

    /**
     * @property {string} stemId The stem id
     */
    this.stemId = null;

    /**
     * @property {string} elementId The element id
     */
    this.elementId = null;

    /**
     * @property {array<AnswerOptionModel>} answerOptions An array of answer options
     */
    this.answerOptions = null;

    /**
     * @property {bool} showAnswerToggle Determines whether the show answer toggle will be visible
     */
    this.showAnswerToggle = false;

    /**
     * @property {string} correctAnswer The correct answer
     */
    this.correctAnswer = '';

    /**
     * @property {bool} showAnswerClicked Determines whether the show answer button was clicked
     */
    this.showAnswerClicked = false;

    /**
     * @property {array<Object>} hintGroup contains array of objects
     */
    this.hintGroup = null;

    /**
     * @property {array<Object>} readExplanation contains array of objects
     */
    this.readExplanation = null;

    /**
     * @property {number} totalHintsViewed viewed for an item
     */
    this.totalHintsViewed = 0;

    /**
     * @property {array<Object>} itemSubTypeVideoExplanation contains array of objects
     */
    this.itemSubTypeVideoExplanation = null;

    /**
     * @property {array<Object>} itemSubTypeTextBasedExplanation contains array of objects
     */
    this.itemSubTypeTextBasedExplanation = null;

    /**
     * @property {string} area subtype of drillset
     */
    this.skill = null;

    /**
     * @property {string} difficulty level of drillset
     */
    this.difficultLevelName = null;

    /**
     * @property {number} totalTimeTaken time taken for a question
     */

    this.totalTimeTaken = 0;
  }
}

ItemModel.propTypes = {
  itemNumber: PropTypes.number.isRequired,
  markForReview: PropTypes.bool,
  isAnswered: PropTypes.bool,
  answer: PropTypes.string,
  stimulusId: PropTypes.string.isRequired,
  stemId: PropTypes.string.isRequired,
  elementId: PropTypes.string.isRequired,
  correctAnswer: PropTypes.string,
  answerOptions: PropTypes.arrayOf(PropTypes.instanceOf(answerOptionModel))
    .isRequired,
  showAnswerClicked: PropTypes.bool,
  hintGroup: PropTypes.arrayOf(PropTypes.object),
  readExplanation: PropTypes.arrayOf(PropTypes.object),
  totalHintsViewed: PropTypes.number,
  itemSubTypeVideoExplanation: PropTypes.arrayOf(PropTypes.object),
  itemSubTypeTextBasedExplanation: PropTypes.arrayOf(PropTypes.object),
  skill: PropTypes.string,
  difficultLevelName: PropTypes.string,
  totalTimeTaken: PropTypes.number,
};

export default ItemModel;

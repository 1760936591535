import { defineMessages } from 'react-intl';

export const scope = 'app.containers.DiagnosticPage';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'Diagnostics',
  },
  mainNavigation: {
    id: `${scope}.mainNavigation`,
    defaultMessage: 'Main Navigation',
  },
  heading1: {
    id: `${scope}.heading1`,
    defaultMessage: 'Something went wrong. But don’t worry…',
  },
  saveText: {
    id: `${scope}.saveText`,
    defaultMessage: 'SAVE diagnostics locally',
  },
  message1: {
    id: `${scope}.message1`,
    defaultMessage:
      '<p>Something has gone wrong with saving data back to our servers but don’t worry, LawHub has built in redundancies and troubleshooting methods for just this type of occurrence.  We just need a little help from you.</p><p>Please click the button below to save the information we need to a file on your computer. Once saved, please contact our support team <a href="https://www.lsac.org/contact" target="_blank">here</a>. When contacting support, please include additional technical details such as your computer OS, browser, and a description of the issue along with the steps you took before the problem occurred.</p>',
  },
  message2: {
    id: `${scope}.message2`,
    defaultMessage:
      'We appreciate your help in our efforts to improve LawHub and ensure this issue is resolved.',
  },
});

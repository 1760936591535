/**
 *
 * TestComplete
 *
 */

import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Container, Row } from 'reactstrap';
import 'styles/common.css';
import './index.css';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import * as moduleHelper from 'helpers/moduleHelper';
import directionsPageReducer from '../DirectionsPage/reducer';
import testSelectPageSaga from '../TestSelectPage/saga';
import testSelectPageReducer from '../TestSelectPage/reducer';
import {
  initSagaLibrarySelector,
  testInstanceStatusSelector,
  testInstanceSummarySelector,
} from '../TestSelectPage/selectors';
import { instancesSelector } from '../DirectionsPage/selectors';
import { routerSelector } from '../App/selectors';
import { BREADCRUMB } from '../MasterPage/constants';
import { updateBreadCrumb } from '../MasterPage/actions';
import ApolloContainer from '../../components/ApolloContainer';
import AriadneLoading from '../../components/ariadne/AriadneLoading/AriadneLoading';
import {
  getInstanceAction,
  changeSelectionAction,
  setSectionAction,
} from '../DirectionsPage/actions';
import history from '../../utils/history';
import { getUserId } from '../../helpers/userHelper';
import { getScoreReportPDF } from '../../helpers/sagaHelper';
import { reactPlugin } from '../TestSelectPage/testselect.util';
import ModulePanel from '../../components/ModulePanel';
import AnswersPanel from '../../components/AnswersPanel';
import WritingResponsePanel from '../../components/WritingResponsePanel';
import * as logHelper from '../../helpers/logHelper';
import { LogLevelType } from '../../enumerations/LogLevelType';
import { getInstanceById } from '../../helpers/persistence/common/repoHelper';
import { isRealExam } from '../../helpers/moduleHelper';
import {
  EXAM_MODULE_PATH,
  MODULE_PATH,
  LIBRARY_PATH,
  DRILLSET_PATH,
  ADDITIONAL_PRACTICE_TEST_PATH,
} from '../App/constants';
import { ModuleType } from '../../enumerations/ModuleType';
import { config } from '../../config';
import ConfirmExamSaved from '../../components/ConfirmExamSaved';
import {
  clearTestInstanceStatusAction,
  getTestInstanceStatusAction,
} from '../TestSelectPage/actions';

export function TestComplete({
  instances,
  initSagaLibrary,
  match,
  onBreadCrumbLoading,
  onGetInstance,
  isScoreReport,
  testSummary,
  shouldLoadInitially = false,
  onChangeSelection,
  onSetSection,
  testInstanceStatus,
  onGetTestInstanceStatus,
  onClearTestInstanceStatus,
  isExamVerified = false,
}) {
  useInjectReducer({ key: 'testSelectPage', reducer: testSelectPageReducer });
  useInjectReducer({ key: 'directionsPage', reducer: directionsPageReducer });
  useInjectSaga({ key: 'testSelectPage', saga: testSelectPageSaga });

  const fContainer = 'flexbox-container';
  const sectionAndRole = "section[role='main']";
  const { testInstanceId } = match.params;
  const instance = moduleHelper.getInstance(instances, testInstanceId);
  const [instanceState, setInstanceState] = useState(null);

  const [isLoadingScoreReportPDF, setIsLoadingScoreReportPDF] = useState(
    !shouldLoadInitially,
  );
  const [isExamComplete, setIsExamComplete] = useState(isExamVerified);

  if (
    !instance ||
    !instance.summary ||
    !instance.module ||
    !instance.module.sections ||
    (instance.module.sections.length === 0 && initSagaLibrary)
  ) {
    onGetInstance({ testInstanceId, userId: getUserId() });
  }

  const module = instance?.module;

  const isWriting =
    module?.options?.moduleType === ModuleType.Writing ||
    module?.options?.moduleType === ModuleType.WritingWithPerspectives;
  const isDrillSet = module?.moduleType === ModuleType.DrillSet;
  const isAdditionalPracticeSet =
    module?.options?.moduleType === ModuleType.DrillSetAdditional;
  const writingText = module?.sections[0]?.items[0]?.writingText;

  const headingText1 = isScoreReport ? module?.moduleName : 'You just finished';
  let headingText2 = '';

  if (isScoreReport) {
    headingText2 = isWriting ? '' : 'Score Report';
  } else {
    headingText2 = `${module?.moduleName}!`;
  }

  // Move to module page if test is not completed or no module.
  // If no module, we are fetching instance above. #12388
  if (!moduleHelper.hasAllSectionsCompleted(module) && !isExamComplete) {
    if (isRealExam(module)) {
      history.push(`${EXAM_MODULE_PATH}/${testInstanceId}`);
    } else {
      history.push(`${MODULE_PATH}/${testInstanceId}`);
    }
  }

  useEffect(() => {
    const fetchedState =
      instance?.state ?? getInstanceById(testInstanceId)?.state;
    setInstanceState(fetchedState);
  }, [testInstanceId]);

  useEffect(() => {
    if (testSummary && testSummary.endTime) {
      if (instance) {
        instance.summary = testSummary;
      }
      setIsLoadingScoreReportPDF(false);
    }
  }, [testSummary]);

  useEffect(() => {
    if (instance && instance.summary) {
      setIsLoadingScoreReportPDF(false);
      if (isDrillSet || isAdditionalPracticeSet) {
        onSetSection(instance.module.sections[0], testInstanceId);
      }
    }
  }, [instance]);

  useEffect(() => {
    if (isScoreReport && module?.modulueName) {
      document.title = `${module.moduleName} Score Report`;
    }

    const breadCrumbSettings = {
      ...BREADCRUMB.settings,
      isVisible: true,
    };

    onBreadCrumbLoading(breadCrumbSettings);

    return () => {
      document.getElementsByClassName(
        fContainer,
      )[0].style.backgroundColor = null;
      document.querySelector(sectionAndRole).style.margin = null;
      onBreadCrumbLoading({});
    };
  }, []);

  function goToLibrary() {
    if (
      isDrillSet &&
      config.REACT_APP_IS_LIBRARY_NEXT_REDIRECT_ENABLED === 'true'
    ) {
      history.push(DRILLSET_PATH);
    } else if (
      isAdditionalPracticeSet &&
      config.REACT_APP_IS_LIBRARY_NEXT_REDIRECT_ENABLED === 'true'
    ) {
      history.push(ADDITIONAL_PRACTICE_TEST_PATH);
    } else {
      history.push(LIBRARY_PATH);
    }
  }

  function loadScoreReportPDF() {
    setIsLoadingScoreReportPDF(true);
    getScoreReportPDF(testInstanceId)
      .then(data => {
        // Create a Blob from the PDF Stream
        const file = new Blob([data], { type: 'application/pdf' });

        // Build a URL from the file
        const fileURL = `${URL.createObjectURL(file)}`;

        // Open the URL on new Window
        window.open(fileURL);
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        logHelper.log(LogLevelType.Error, 'ScoreReportPDF Error: ', error);
        // eslint-disable-next-line no-alert
        alert('Unable to load the score report PDF');
      })
      .finally(() => {
        setIsLoadingScoreReportPDF(false);
      });
  }

  function verifyExamCompletion() {
    setIsExamComplete(true);
  }

  if (instance && !isLoadingScoreReportPDF && isExamComplete) {
    return (
      <ApolloContainer childClassName="test-complete">
        <div className="test-complete-container-wrapper">
          <div className="test-complete-container ">
            <div
              className={`test-complete-module-panel-container left-complete-panel-container ${
                isAdditionalPracticeSet ? 'additional-practice-left-panel' : ''
              }`}
            >
              <ModulePanel
                testInstanceId={testInstanceId}
                instance={
                  instance.summary
                    ? instance
                    : getInstanceById(testInstanceId, false)
                }
                sections={module.sections}
                isScoreReport={isScoreReport}
                isWriting={isWriting}
                headingText1={headingText1}
                headingText2={headingText2}
                goToLibrary={goToLibrary}
                loadScoreReportPDF={() => loadScoreReportPDF()}
                isDrillSet={isDrillSet}
                isAdditionalPracticeSet={isAdditionalPracticeSet}
                onChangeSelection={onChangeSelection}
              />
            </div>
            {isWriting && (
              <div className="test-complete-writing-response-panel-container">
                <WritingResponsePanel writingSectionResponse={writingText} />
              </div>
            )}
            {!isWriting && (
              <div className="test-complete-answers-panel-container">
                <AnswersPanel
                  instance={{
                    ...instance,
                    state: instanceState ?? instance?.state ?? {},
                  }}
                  isScoreReport={isScoreReport}
                  isWriting={isWriting}
                  onChangeSelection={onChangeSelection}
                />
              </div>
            )}
          </div>
          {(isDrillSet || isAdditionalPracticeSet) && (
            <div className="standing-image" data-testid="standingImage" />
          )}
        </div>
      </ApolloContainer>
    );
  }
  return (
    <Container fluid style={{ width: '100%' }}>
      {!isExamComplete ? (
        <ConfirmExamSaved
          getTestInstanceStatus={onGetTestInstanceStatus}
          testInstanceStatus={testInstanceStatus}
          testInstanceId={testInstanceId}
          clearTestInstanceStatus={onClearTestInstanceStatus}
          verificationCallback={() => verifyExamCompletion()}
          isPrepTest={!isRealExam(module)}
        />
      ) : (
        <Row>
          <AriadneLoading text={module?.moduleName} />
        </Row>
      )}
    </Container>
  );
}

TestComplete.propTypes = {
  instances: PropTypes.array,
  initSagaLibrary: PropTypes.bool,
  match: PropTypes.object,
  onBreadCrumbLoading: PropTypes.func.isRequired,
  onGetInstance: PropTypes.func.isRequired,
  isScoreReport: PropTypes.bool,
  testSummary: PropTypes.object,
  shouldLoadInitially: PropTypes.bool,
  onChangeSelection: PropTypes.func,
  onSetSection: PropTypes.func,
  testInstanceStatus: PropTypes.object,
  onGetTestInstanceStatus: PropTypes.func,
  onClearTestInstanceStatus: PropTypes.func,
  isExamVerified: PropTypes.bool,
};

const mapStateToProps = createStructuredSelector({
  instances: instancesSelector(),
  router: routerSelector(),
  initSagaLibrary: initSagaLibrarySelector(),
  testSummary: testInstanceSummarySelector(),
  testInstanceStatus: testInstanceStatusSelector(),
});

export function mapDispatchToProps(dispatch) {
  return {
    onBreadCrumbLoading: breadCrumbSection =>
      dispatch(updateBreadCrumb(breadCrumbSection)),
    onGetInstance: testInstanceId =>
      dispatch(getInstanceAction(testInstanceId)),
    onChangeSelection: index => dispatch(changeSelectionAction(index)),
    onSetSection: (section, testInstanceId) =>
      dispatch(setSectionAction(section, testInstanceId)),
    onClearTestInstanceStatus: () => dispatch(clearTestInstanceStatusAction()),
    onGetTestInstanceStatus: (userId, instanceId, isPrepExam) =>
      dispatch(getTestInstanceStatusAction(userId, instanceId, isPrepExam)),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

export default compose(withConnect)(
  withAITracking(reactPlugin, injectIntl(TestComplete), 'TestComplete'),
);

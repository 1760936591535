/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable indent */
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import PropTypes from 'prop-types';
import { isNullOrUndefined } from 'util';
import { intlShape, FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import LawHubLogoWhite from 'images/LawHubLogo-White.svg';
import LawHubLogoBlue from 'images/LawHubLogo-Blue.svg';
import DashboardIconMaterial from 'images/icon-dashboard-material.svg';
import DashboardIcon from 'images/nav-home-icon.svg';
import ExploreIconMaterial from 'images/icon-explore-material.svg';
import LibraryIconMaterial from 'images/icon-prep-material.svg';
import LibraryIcon from 'images/nav-prep-icon.svg';
import TutorialsIconMaterial from 'images/icon-tutorials-material.svg';
import TutorialsIcon from 'images/nav-tutorials-icon.svg';
import MarketplaceIcon from 'images/nav-marketplace-icon.svg';
import LsatIcon from 'images/nav-lsat-icon.svg';
import LawHubIcon from 'images/LawHubIcon-White.svg';
import ExpandIcon from 'images/sidebarNav-expand-icon.svg';
import CollapseIcon from 'images/sidebarNav-collapse-icon.svg';
import CloseIcon from 'images/close_icon_white_material.svg';
import LearningLibraryIconMaterial from 'images/icon-learning-library-material.svg';
import LearningLibraryIcon from 'images/ico_book.svg';
import HelpIcon from 'images/sidebarNav-help-icon.svg';
import ExternalLinkIcon from 'images/external_link_outward_rebrand.svg';
import ScaleIcon from 'images/scale.svg';
import { config } from 'config';
import messages from '../messages';
import UserProfile from '../../../components/UserProfile';
import ReloadingLink from '../../../components/ReloadingLink/index';
import {
  HOME_PATH,
  LIBRARY_PATH,
  CERTIFYING_STATEMENT_PATH,
  FLEX_LANDING_PATH,
  LSAT_FLEX_PATH,
  TUTORIALS_PATH,
  ACCOUNT_SETTINGS_PATH,
  UPGRADE_PATH,
  MARKETPLACE_PATH,
  EXAM_PAUSE_PATH,
  EXPLORE_PATH,
  LST_PATH,
} from '../../App/constants';

import './index.css';
import { isPrometrics } from '../../../helpers/examHelper';
import { logout } from '../../../authProvider';

function SidebarNav({
  sidebarId,
  sidebarCloseButtonId,
  sidebarCloseButtonIdRebrand,
  pathname,
  isFullScreen,
  isHeaderWithHelpLink,
  isSideNavBarVisible,
  isAppReady,
  hasToShowSideNavBar,
  onDisplaySideNavBar,
  onSideNavCloseButtonKeypress,
  onSideNavKeyDown,
  sidebarLinkClicked,
  closeMenuButtonClicked,
  history,
  userDetails,
  windowWidth,
  configShowFlexList,
  intl,
  exams,
}) {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isPrometricsUser, setIsPrometricsUser] = useState(false);
  const isDesktopWidth = windowWidth > 1170;
  const isTabletWidth = windowWidth > 675 && windowWidth <= 1170;
  const isMobileWidth = windowWidth <= 675;
  const isPremiumAccount =
    userDetails?.subscriptionStatus?.subscriptionType.toLowerCase() ===
    'prepplus';

  const location = useLocation();
  const isExamRoute = location?.pathname?.indexOf('/exam') >= 0;

  // To hide the Lawhub logo from side nav in mobile view on the Registration Notification screen
  const examPaths = [FLEX_LANDING_PATH, CERTIFYING_STATEMENT_PATH];
  const hideLogoinNav =
    examPaths.includes(window.location.pathname) && isMobileWidth;

  // If SidebarNav is collapsed and the user resizes so that it
  // switches to its tablet/mobile behavior, reset isCollapsed
  // so that the bottom nav controls display correctly.
  useEffect(() => {
    if (!isDesktopWidth) {
      setIsCollapsed(false);
    }
  }, [isDesktopWidth]);

  useEffect(() => {
    setIsPrometricsUser(isPrometrics(exams ? exams[0] : undefined));
  }, [exams]);

  const onSidebarTransitionEnd = e => {
    const isSideNavTarget = e?.target?.id === sidebarId;
    const nav = document.getElementById(sidebarId);
    if (!isNullOrUndefined(nav)) {
      nav.setAttribute('aria-expanded', `${isSideNavBarVisible}`);
      nav.setAttribute('aria-hidden', `${!isSideNavBarVisible}`);
    }
    const closeButton = document.getElementById(sidebarCloseButtonId);
    const lawHubLogoInNav = document.getElementById('lsacLink');
    const isCloseButtonDisplayed =
      closeButton &&
      window.getComputedStyle(closeButton).getPropertyValue('display') !==
        'none';
    const closeButtonRebrand = document.getElementById(
      sidebarCloseButtonIdRebrand,
    );

    if (
      !isNullOrUndefined(closeButton) &&
      isSideNavBarVisible &&
      isSideNavTarget &&
      isCloseButtonDisplayed
    ) {
      closeButton.focus();
    } else if (closeButtonRebrand && isTabletWidth) {
      closeButtonRebrand?.focus();
    } else if (lawHubLogoInNav && isMobileWidth) {
      lawHubLogoInNav?.focus();
    } else if (hideLogoinNav && closeButtonRebrand && isMobileWidth) {
      closeButtonRebrand?.focus();
    }
  };

  // For Certifying statment page
  useEffect(() => {
    if (!isDesktopWidth && isSideNavBarVisible) {
      onSidebarTransitionEnd();
    }
  }, [isSideNavBarVisible]);

  const onCollapseSidebar = e => {
    const enterKeyPressed = e.which === 13 || e.key === 'Enter';
    const spaceKeyPressed = e.which === 32 || e.key === ' ';
    if (enterKeyPressed || spaceKeyPressed || e.type === 'click') {
      setIsCollapsed(!isCollapsed);
    }
  };

  const getActiveNavClass = () => {
    let libraryClassName = '';
    let tutorialClassName = '';
    let marketplaceClassName = '';
    let learningLibraryClassName = '';
    let dashboardClassName = '';
    let exploreClassName = '';
    let lstClassName = '';
    let flexClassName = '';
    let myAccountClassName = 'nav-my-account-link';

    try {
      const srcstring = pathname;
      // split string
      const navItemsHref = srcstring.split('/');
      navItemsHref.shift(); // remove empty begining /.
      const setCurrentBaseNavHref = `${navItemsHref[0]}`;
      switch (setCurrentBaseNavHref.toLowerCase()) {
        case '':
        case 'dashboard':
          dashboardClassName = 'active';
          break;
        case 'explore':
          exploreClassName = 'active';
          break;
        case 'law-school-transparency':
          lstClassName = 'active';
          break;
        case 'module':
        case 'section':
        case 'passage':
        case 'testreview':
        case 'question':
        case 'history':
        case 'library':
          libraryClassName = 'active';
          break;
        case 'tutorials':
        case 'tutorial':
          tutorialClassName = 'active';
          break;
        case 'marketplace':
          marketplaceClassName = 'active';
          break;
        case 'learninglibrary':
        case 'programs':
        case 'eventdetails':
          learningLibraryClassName = 'active';
          break;
        case 'flex':
        case 'examTestComplete':
        case 'flexbegin':
        case 'certifyingstatement':
        case 'connectivityerror':
        case 'exam':
        case 'prep':
          flexClassName = 'active';
          break;
        case 'account':
          myAccountClassName = `${myAccountClassName} active`;
          break;
        default:
          break;
      }
      // eslint-disable-next-line no-empty
    } catch (error) {}

    return {
      libraryClassName,
      dashboardClassName,
      exploreClassName,
      tutorialClassName,
      marketplaceClassName,
      learningLibraryClassName,
      flexClassName,
      myAccountClassName,
      lstClassName,
    };
  };

  // We need to dynamically adjust when the botton nav section appears.  We also
  // need to adjust some of its styles as long as some pages have headers without
  // the help link.
  const bottomNavStylesConditional = () => isHeaderWithHelpLink || !isCollapsed;
  const shouldRenderBottomNav = () =>
    isDesktopWidth || !isHeaderWithHelpLink || isMobileWidth;
  const shouldRenderHelpLink = () =>
    userDetails && (isMobileWidth || !isHeaderWithHelpLink);
  const shouldRenderHelpIcon = () => isCollapsed && isDesktopWidth;

  const navLinksData = () => {
    const flexPath =
      configShowFlexList === 'false'
        ? CERTIFYING_STATEMENT_PATH
        : LSAT_FLEX_PATH;

    const {
      libraryClassName,
      tutorialClassName,
      marketplaceClassName,
      learningLibraryClassName,
      dashboardClassName,
      exploreClassName,
      flexClassName,
      myAccountClassName,
      lstClassName,
    } = getActiveNavClass();

    const flexLink = {
      id: 'flexLink',
      path: flexPath,
      iconSrc: LsatIcon,
      message: messages.lsatLinkText,
      className: flexClassName,
    };
    const dashboardLink = {
      id: 'dashboardLink',
      path: HOME_PATH,
      iconSrc:
        config.REACT_APP_BRAND_UPDATE === 'true'
          ? DashboardIconMaterial
          : DashboardIcon,
      message: messages.dashboardLinkText,
      className: dashboardClassName,
    };
    const exploreLink = {
      id: 'exploreLink',
      path: EXPLORE_PATH,
      iconSrc: ExploreIconMaterial,
      message: messages.exploreLinkText,
      className: exploreClassName,
    };
    const lawSchoolTransparencyLink = {
      id: 'lawSchoolTransparencyLink',
      path: LST_PATH,
      iconSrc: ScaleIcon,
      message: messages.lstLinkText,
      className: lstClassName,
    };
    const libraryLink = {
      id: 'libraryLink',
      path: LIBRARY_PATH,
      iconSrc:
        config.REACT_APP_BRAND_UPDATE === 'true'
          ? LibraryIconMaterial
          : LibraryIcon,
      message: messages.lsatPrepTestLinkText,
      className: libraryClassName,
    };
    const learningLibraryLink = {
      id: 'learningLibraryLink',
      path: '/learningLibrary',
      iconSrc:
        config.REACT_APP_BRAND_UPDATE === 'true'
          ? LearningLibraryIconMaterial
          : LearningLibraryIcon,
      message: messages.learningLibraryLinkText,
      className: learningLibraryClassName,
    };
    const marketplaceLink = {
      id: 'marketplaceLink',
      path: MARKETPLACE_PATH,
      iconSrc: MarketplaceIcon,
      message: messages.marketplaceLinkText,
      className: marketplaceClassName,
    };
    const tutorialsLink = {
      id: 'tutorialsLink',
      path: TUTORIALS_PATH,
      iconSrc:
        config.REACT_APP_BRAND_UPDATE === 'true'
          ? TutorialsIconMaterial
          : TutorialsIcon,
      message: messages.tutorialsLinkText,
      className: tutorialClassName,
    };
    const myAccountLink = {
      id: 'myAccountLink',
      path: ACCOUNT_SETTINGS_PATH,
      message: messages.myAccountLinkText,
      className: myAccountClassName,
    };
    const upgradeLink = {
      id: 'upgradeLink',
      path: UPGRADE_PATH,
      message: messages.upgradeLinkText,
      className: 'nav-upgrade-link',
    };

    return {
      flexLink,
      dashboardLink,
      exploreLink,
      libraryLink,
      tutorialsLink,
      learningLibraryLink,
      marketplaceLink,
      myAccountLink,
      upgradeLink,
      lawSchoolTransparencyLink,
    };
  };

  const renderNavLink = linkObj => {
    // Check if linkObj has keys
    if (Object.keys(linkObj).length > 0) {
      const { id, path, iconSrc, message, className } = linkObj;
      const args = [iconSrc, message, id, path, className];
      // Validate linkObj's properties
      const argsAreDefined = args.every(
        arg => arg !== undefined && arg !== null,
      );
      const isCurrentPage = className?.includes('active');

      const linkText = message.defaultMessage;
      if (argsAreDefined) {
        return (
          <li role="listitem" className={className}>
            {isCollapsed && isDesktopWidth ? (
              <ReloadingLink
                id={id}
                to={path}
                history={history}
                callFunctionBeforeReload={sidebarLinkClicked}
                ariaCurrent={isCurrentPage && 'page'}
              >
                <span className="link-icon-container">
                  <img
                    src={iconSrc}
                    id={id}
                    alt={linkText}
                    title={linkText}
                    className=""
                  />
                </span>
              </ReloadingLink>
            ) : (
              <React.Fragment>
                <span className="link-icon-container">
                  <img
                    src={iconSrc}
                    id={id}
                    alt={linkText}
                    className={`${
                      linkText === intl.formatMessage(messages.lstLinkText)
                        ? 'lstMenuClass'
                        : ''
                    }`}
                  />
                </span>
                <ReloadingLink
                  id={id}
                  to={path}
                  history={history}
                  callFunctionBeforeReload={sidebarLinkClicked}
                  ariaCurrent={isCurrentPage && 'page'}
                >
                  <React.Fragment>
                    <FormattedMessage {...message}>
                      {(...content) => content}
                    </FormattedMessage>
                  </React.Fragment>
                </ReloadingLink>
              </React.Fragment>
            )}
          </li>
        );
      }
    }
    return <React.Fragment />;
  };

  const renderHelpLink = () =>
    shouldRenderHelpLink() && (
      <li
        role="listitem"
        id="classicLink"
        className="classicLink"
        style={{
          top: bottomNavStylesConditional() ? '1.5rem' : '1.3rem',
        }}
      >
        <a
          href={config?.REACT_APP_HELP_URL}
          target="_blank"
          title={`${
            shouldRenderHelpIcon() ? 'Help, ' : ''
          }will open in a new browser window`}
        >
          {shouldRenderHelpIcon() ? (
            <React.Fragment>
              <img id="helptext" className="helpIcon" src={HelpIcon} alt="" />
              <span className="hidden">
                <FormattedMessage {...messages.helpLinkText} />
              </span>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {config.REACT_APP_BRAND_UPDATE === 'true' ? (
                <img
                  src={ExternalLinkIcon}
                  className="external-link-icon"
                  alt=""
                />
              ) : (
                <i className="fas fa-external-link-alt" />
              )}
              <span id="helptext">
                <FormattedMessage {...messages.helpLinkText} />
              </span>
            </React.Fragment>
          )}
          <span className="hidden">will open in a new browser window</span>
        </a>
      </li>
    );

  const renderUserProfile = () =>
    userDetails &&
    (isMobileWidth || !isHeaderWithHelpLink) && (
      <div id="respUserProfileLinks">
        <UserProfile onDisplaySideNavBar={onDisplaySideNavBar} />
      </div>
    );

  const renderCollapseExpandBtns = () => {
    const titleAndAriaLabelText = isCollapsed
      ? 'Expand the menu'
      : 'Collapse the menu';
    const iconSrc = isCollapsed ? ExpandIcon : CollapseIcon;
    const iconAltText = isCollapsed ? 'Expand icon' : 'Collapse icon';

    return (
      <i
        className="collapseExpandNavBtns"
        style={{
          top: bottomNavStylesConditional() ? '1.7rem' : '4.0rem',
        }}
        role="button"
        onClick={e => onCollapseSidebar(e)}
        onKeyDown={e => onCollapseSidebar(e)}
        tabIndex="0"
        title={titleAndAriaLabelText}
        aria-label={titleAndAriaLabelText}
        aria-controls={sidebarId}
        aria-expanded={!isCollapsed}
      >
        <img src={iconSrc} alt={iconAltText} />
      </i>
    );
  };

  const getNavigation = () => {
    const {
      flexLink,
      dashboardLink,
      exploreLink,
      libraryLink,
      tutorialsLink,
      learningLibraryLink,
      marketplaceLink,
      myAccountLink,
      upgradeLink,
      lawSchoolTransparencyLink,
    } = navLinksData();

    const primaryNavLinks = () => {
      // do not add any primary links in the menu if we on a real exam screen
      if (isExamRoute) return [];

      const navLinksSansTutorials = [
        dashboardLink,
        config.REACT_APP_IS_EXPLORE_ENABLED === 'true'
          ? exploreLink
          : undefined,
        libraryLink,
        config.REACT_APP_IS_LST_FEATURE_ENABLED === 'true'
          ? lawSchoolTransparencyLink
          : undefined,
        learningLibraryLink,
        config.REACT_APP_IS_MARKETPLACE_FEATURE_ENABLED === 'true'
          ? marketplaceLink
          : undefined,
      ].filter(Boolean);

      return !userDetails
        ? navLinksSansTutorials
        : [...navLinksSansTutorials, tutorialsLink];
    };

    return (
      <React.Fragment>
        {userDetails?.isExamScheduled &&
          !isExamRoute &&
          renderNavLink(flexLink)}
        {primaryNavLinks()?.map(link => (
          <React.Fragment key={link.id}>{renderNavLink(link)}</React.Fragment>
        ))}

        {config.REACT_APP_ENABLE_NEW_NAV === 'true' &&
          isMobileWidth &&
          !isExamRoute && (
            <>
              {userDetails && (
                <>
                  <div className="static-nav-divider" />
                  {!isPremiumAccount && (
                    <li>
                      <ReloadingLink
                        id={upgradeLink.id}
                        to={upgradeLink.path}
                        history={history}
                        callFunctionBeforeReload={sidebarLinkClicked}
                        className={upgradeLink.className}
                      >
                        <React.Fragment>
                          <FormattedHTMLMessage {...upgradeLink.message} />
                        </React.Fragment>
                      </ReloadingLink>
                    </li>
                  )}
                  <li>
                    <ReloadingLink
                      id={myAccountLink.id}
                      to={myAccountLink.path}
                      history={history}
                      callFunctionBeforeReload={sidebarLinkClicked}
                      className={myAccountLink.className}
                    >
                      <React.Fragment>
                        <FormattedMessage {...myAccountLink.message}>
                          {(...content) => content}
                        </FormattedMessage>
                      </React.Fragment>
                    </ReloadingLink>
                  </li>
                  <div className="static-nav-divider" />
                  <li>
                    <button
                      type="button"
                      onClick={logout}
                      className="sign-out-button"
                      id="sign-out-button-nav"
                    >
                      <FormattedMessage {...messages.signOutButtonText}>
                        {(...content) => content}
                      </FormattedMessage>
                    </button>
                  </li>
                </>
              )}
            </>
          )}

        {/* show only signout link in the menu if we on a real exam screen */}
        {isExamRoute && (
          <>
            <li>
              <button
                type="button"
                onClick={logout}
                className="sign-out-button"
                id="sign-out-button-nav"
              >
                <FormattedMessage {...messages.signOutButtonText}>
                  {(...content) => content}
                </FormattedMessage>
              </button>
            </li>
          </>
        )}

        {/* do not show user profile links when in exam mode */}
        {!isExamRoute && renderUserProfile()}

        {shouldRenderBottomNav() && (
          <div
            id="bottomLinks"
            style={{
              height: bottomNavStylesConditional() ? '4.5rem' : '6.5rem',
              position:
                userDetails && !isDesktopWidth ? 'relative' : 'absolute',
            }}
          >
            <div
              className={isCollapsed ? 'thin-nav-divider' : 'wide-nav-divider'}
            />
            {renderHelpLink()}
            {isDesktopWidth && renderCollapseExpandBtns()}
          </div>
        )}
      </React.Fragment>
    );
  };

  const renderLogo = () => {
    const whiteLogoOnly =
      isCollapsed && isDesktopWidth ? (
        <img
          id="lawHubIcon"
          alt={`${intl.formatMessage({
            ...messages.logoHome,
          })}`}
          src={LawHubIcon}
        />
      ) : (
        <img
          id="lawHubLogo"
          alt={`${intl.formatMessage({
            ...messages.logoHome,
          })}`}
          src={LawHubLogoWhite}
        />
      );

    const whiteOrBlueLogo =
      isCollapsed && isDesktopWidth ? (
        <img
          id="lawHubIcon"
          alt={`${intl.formatMessage({
            ...messages.logoHome,
          })}`}
          src={LawHubIcon}
        />
      ) : (
        <img
          id="lawHubLogo"
          alt={`${intl.formatMessage({
            ...messages.logoHome,
          })}`}
          src={isDesktopWidth ? LawHubLogoWhite : LawHubLogoBlue}
        />
      );

    return config.REACT_APP_ENABLE_NEW_NAV === 'true'
      ? whiteOrBlueLogo
      : whiteLogoOnly;
  };

  const hideSideNavForPrometrics = () => {
    const pathsWithoutSideNav = [CERTIFYING_STATEMENT_PATH, EXAM_PAUSE_PATH];
    const currentWindowPath = window.location.pathname;

    // show hamburger for higher zoom levels in PM
    const hamburgerButton = document.getElementById('Homehamburger');
    if (hamburgerButton && isSideNavBarVisible) return false;

    /* the home page will match the below includes
     * because its /
     * so we need to short circuit early here
     */
    if (currentWindowPath === HOME_PATH) return false;

    const hideBasedOnPath = pathsWithoutSideNav.some(pathWithoutNav =>
      currentWindowPath.startsWith(pathWithoutNav),
    );
    return hideBasedOnPath && isPrometricsUser;
  };

  const sidebarNavClasses = () => {
    if (isDesktopWidth) {
      // Classes for collapsible, thin side nav on desktop
      return hasToShowSideNavBar && isCollapsed
        ? 'thinNav mainNavigation activeNav'
        : 'mainNavigation activeNav';
    }
    // Classes for tablet and mobile
    return hasToShowSideNavBar ? 'mainNavigation activeNav' : 'mainNavigation';
  };

  // SidebarNav component return statement
  if (!isFullScreen && isAppReady && !hideSideNavForPrometrics()) {
    return (
      <nav
        data-testid="sideMenu"
        id={sidebarId}
        onTransitionEnd={e => onSidebarTransitionEnd(e)}
        onKeyDown={e => onSideNavKeyDown(e)}
        className={`${sidebarNavClasses()} ${
          config.REACT_APP_ENABLE_NEW_NAV === 'true' ? 'right-side-nav' : ''
        }`}
      >
        <button
          type="button"
          data-testid="closeButton"
          id="sidebarCloseButton"
          className="sidebarCloseButton icon"
          onClick={() => closeMenuButtonClicked()}
          onKeyPress={e => onSideNavCloseButtonKeypress(e)}
          aria-label="close"
        />
        <div className="logo">
          {!hideLogoinNav && (
            <a id="lsacLink" href={window.location.origin}>
              {renderLogo()}
            </a>
          )}
          {config.REACT_APP_ENABLE_NEW_NAV === 'true' && !isDesktopWidth && (
            <button
              type="button"
              data-testid="closeButton-rebrand"
              id="sidebarCloseButton-rebrand"
              className="sidebarCloseButton-rebrand icon"
              onClick={() => closeMenuButtonClicked()}
              onKeyPress={e => onSideNavCloseButtonKeypress(e)}
              aria-label="close"
            >
              <img src={CloseIcon} alt="close" />
            </button>
          )}
        </div>
        <ul role="list">{getNavigation()}</ul>
      </nav>
    );
  }

  return <React.Fragment />;
}

SidebarNav.propTypes = {
  sidebarId: PropTypes.string,
  sidebarCloseButtonId: PropTypes.string,
  sidebarCloseButtonIdRebrand: PropTypes.string,
  pathname: PropTypes.string,
  configShowFlexList: PropTypes.string,
  isFullScreen: PropTypes.bool,
  isSideNavBarVisible: PropTypes.bool,
  isHeaderWithHelpLink: PropTypes.bool,
  isAppReady: PropTypes.bool,
  hasToShowSideNavBar: PropTypes.bool,
  onDisplaySideNavBar: PropTypes.func,
  onSideNavCloseButtonKeypress: PropTypes.func,
  onSideNavKeyDown: PropTypes.func,
  sidebarLinkClicked: PropTypes.func,
  closeMenuButtonClicked: PropTypes.func,
  history: PropTypes.object,
  userDetails: PropTypes.object,
  windowWidth: PropTypes.number,
  intl: intlShape.isRequired,
  exams: PropTypes.array,
};

export default SidebarNav;

/**
 *
 * AnswersPanel
 *
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './index.css';
import SectionResult from '../SectionResult';
import { ModuleType } from '../../enumerations/ModuleType';

function AnswersPanel({
  instance,
  isScoreReport,
  isWriting,
  onChangeSelection,
}) {
  const [state, setState] = useState({ selectedTab: 0 });
  const isDrillSet = instance?.module?.moduleType === ModuleType.DrillSet;
  const isAdditionalPracticeSet =
    instance?.module?.moduleType === ModuleType.DrillSetAdditional;
  const selectedSectionFormId =
    instance?.module.sections[state.selectedTab].formId;

  function tabClick(index) {
    if (state.selectedTab !== index) {
      setState({ selectedTab: index });
    }
  }

  function getTabs() {
    return instance.module.sections.map((section, index) => (
      <div
        key={index} // eslint-disable-line react/no-array-index-key
        className={
          state.selectedTab === index
            ? 'answers-panel-section-title'
            : 'answers-panel-section-title-unselected'
        }
        onClick={() => tabClick(index)}
        onKeyPress={() => tabClick(index)}
        role="button"
        tabIndex={0}
      >
        {/* update section title to denote variable section */}
        {section.isVariableSection !== undefined &&
        section.isVariableSection !== null &&
        section.isVariableSection === true
          ? `${section.title} (*)`
          : section.title}
      </div>
    ));
  }

  return (
    <div className="answers-panel-container">
      {!isDrillSet && !isAdditionalPracticeSet && (
        <div className="answers-panel-tab-container">{getTabs()}</div>
      )}
      <SectionResult
        section={instance.module.sections[state.selectedTab]}
        sectionFromState={instance.state?.sections?.find(
          section => section.formId === selectedSectionFormId,
        )}
        testInstanceId={instance.testInstanceId}
        hideReviewButton={
          isScoreReport ||
          !instance.module.sections[state.selectedTab].isCompleted
        }
        isWriting={isWriting}
        onChangeSelection={onChangeSelection}
      />
    </div>
  );
}

AnswersPanel.propTypes = {
  instance: PropTypes.object.isRequired,
  isScoreReport: PropTypes.bool,
  isWriting: PropTypes.bool.isRequired,
  onChangeSelection: PropTypes.func,
};

export default AnswersPanel;

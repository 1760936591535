/* eslint-disable react/no-access-state-in-setstate */

import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import 'styles/common.css';
import './index.css';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import * as moduleHelper from 'helpers/moduleHelper';
import { isNullOrUndefined } from 'util';
import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';
import saga from '../TestSelectPage/saga';
import reducer from '../TestSelectPage/reducer';
import directionsPageReducer from '../DirectionsPage/reducer';
import RocketSVG from '../../svg/rocket.svg';
import 'linq';
import { initSagaLibrarySelector } from '../TestSelectPage/selectors';
import {
  instancesSelector,
  selectedInstanceSelector,
} from '../DirectionsPage/selectors';
import { routerSelector } from '../App/selectors';
import { BREADCRUMB } from '../MasterPage/constants';
import { updateBreadCrumb } from '../MasterPage/actions';
import SectionSelect from '../../components/SectionSelect';
import ApolloContainer from '../../components/ApolloContainer';
import AriadneLoading from '../../components/ariadne/AriadneLoading/AriadneLoading';
import { getInstanceAction } from '../DirectionsPage/actions';
import SectionResult from '../../components/SectionResult';
import DirectionsPanel from '../../components/DirectionsPanel';
import { getUserId } from '../../helpers/userHelper';
import history from '../../utils/history';
import { ExamMode } from '../../enumerations/ExamMode';
import { reactPlugin } from '../TestSelectPage/testselect.util';
import { getSectionBreakInfo, isRealExam } from '../../helpers/moduleHelper';
import { EXAM_MODULE_PATH, MODULE_PATH } from '../App/constants';
import { getInstanceById } from '../../helpers/persistence/common/repoHelper';

/**
 * @class TestReview
 * @description This is a results view for a test.
 * @author Chad Adams (Microsoft)
 * @param {*} props
 * @example <TestReview />
 */

// eslint-disable-next-line react/prefer-stateless-function
export class TestReview extends React.Component {
  state = { selectedTab: 0, module: null, selectedSection: null };

  fContainer = 'flexbox-container';

  sectionAndRole = "section[role='main']";

  constructor(props) {
    super(props);
    this.instance = null;
    this.moduleId = null;
    this.nextSection = null;
  }

  getModuleSection() {
    const { testInstanceId, sectionId } = this.props.match.params;
    const selection = moduleHelper.getInstanceSection(
      this.props.instances,
      testInstanceId,
      sectionId,
    );
    this.testInstanceId = testInstanceId;
    this.instance = selection.selectedInstance;
    const module = !isNullOrUndefined(this.instance)
      ? this.instance.module
      : null;
    if (
      (isNullOrUndefined(module) ||
        isNullOrUndefined(module.sections) ||
        module.sections.length === 0) &&
      this.props.initSagaLibrary
    ) {
      this.props.onGetInstance({ testInstanceId, userId: getUserId() });
    }

    /* Redirect to /exam/module for a real exam 
        and /module for a prep exam in Exam Mode */
    if (module && isRealExam(module)) {
      history.push(`${EXAM_MODULE_PATH}/${this.testInstanceId}`);
    } else if (module && module.examMode === ExamMode.ExamTime) {
      history.push(`${MODULE_PATH}/${this.testInstanceId}`);
    }

    this.setState({ module }, () => {
      if (!this.state.selectedSection) {
        this.getSelectedSection();
      }
    });
  }

  componentWillMount() {
    this.getModuleSection();
  }

  componentDidUpdate() {
    if (!this.state.module && this.props.instances?.length > 0) {
      this.getModuleSection();
    }
  }

  componentDidMount() {
    const breadCrumbSettings = {
      ...BREADCRUMB.settings,
      isVisible: true,
    };
    this.props.onBreadCrumbLoading(breadCrumbSettings);
  }

  componentWillUnmount() {
    document.getElementsByClassName(
      this.fContainer,
    )[0].style.backgroundColor = null;
    document.querySelector(this.sectionAndRole).style.margin = null;
    this.props.onBreadCrumbLoading({});
  }

  getSelectedSection() {
    const info = getSectionBreakInfo(
      this.state.module,
      this.props.match.params.sectionId,
    );
    this.nextSection = info.nextSection;
    this.setState({ selectedSection: info.selectedSection });
  }

  titleTabClick = () => {
    if (this.state.selectedTab !== 0) {
      this.setState({ selectedTab: 0 });
    }
  };

  sectionTabClick = () => {
    if (this.state.selectedTab !== 1) {
      this.setState({ selectedTab: 1 });
    }
  };

  render() {
    const { module, selectedSection } = this.state;
    if (
      !isNullOrUndefined(module) &&
      !isNullOrUndefined(module.sections) &&
      module.sections.length > 0 &&
      selectedSection !== null
    ) {
      const titleTabClass =
        this.state.selectedTab === 0
          ? 'answers-panel-section-title'
          : 'answers-panel-section-title-unselected';
      const selectionTabClass =
        this.state.selectedTab === 1
          ? 'answers-panel-section-title'
          : 'answers-panel-section-title-unselected';
      const showRocket = this.state.selectedTab === 1;
      const instanceFromRepo = getInstanceById(this.testInstanceId, false);

      return (
        <ApolloContainer>
          <div className="test-review-container">
            <div className="test-review-answers-panel">
              {showRocket && (
                <div className="test-review-rocket-container">
                  <RocketSVG />
                </div>
              )}
              <h1 className="hidden">
                Section Responses and Section Selection
              </h1>
              <span className="hidden">
                Selecting a button below will change the content to correspond
                with your selection.
              </span>
              <div className="test-review-tab-container">
                <div
                  id="sectionTitle"
                  className={titleTabClass}
                  onClick={this.titleTabClick}
                  onKeyPress={this.titleTabClick}
                  role="button"
                  tabIndex={0}
                >
                  {selectedSection &&
                  selectedSection.title &&
                  selectedSection.title.length > 0
                    ? `${selectedSection.title} Responses`
                    : ''}
                </div>
                <div
                  className={selectionTabClass}
                  onClick={this.sectionTabClick}
                  onKeyPress={this.sectionTabClick}
                  role="button"
                  tabIndex={0}
                >
                  Section Selection
                </div>
              </div>
              {this.state.selectedTab === 0 && (
                <SectionResult
                  section={selectedSection}
                  testInstanceId={this.testInstanceId}
                  sectionFromState={(
                    instanceFromRepo?.state?.sections ?? []
                  ).find(section => section.formId === selectedSection.formId)}
                />
              )}
              {this.state.selectedTab === 1 && (
                <div style={{ height: '30px' }} />
              )}
              {this.state.selectedTab === 1 && (
                <Row>
                  <Col xs={{ size: 12 }}>
                    <SectionSelect
                      module={module}
                      testInstanceId={this.testInstanceId}
                      examMode={
                        !isNullOrUndefined(module.examMode)
                          ? module.examMode
                          : ExamMode.SelfPaced
                      }
                    />
                  </Col>
                </Row>
              )}
            </div>
            <div className="test-review-directions-panel">
              <DirectionsPanel
                section={this.nextSection}
                testInstanceId={this.testInstanceId}
                selectedInstance={this.props.selectedInstance}
              />
            </div>
          </div>
        </ApolloContainer>
      );
    }
    const moduleName = isNullOrUndefined(module) ? 'module' : module.moduleName;
    return (
      <Container fluid style={{ width: '100vw' }}>
        <Row>
          <AriadneLoading text={moduleName} />
        </Row>
      </Container>
    );
  }
}

TestReview.propTypes = {
  instances: PropTypes.array,
  initSagaLibrary: PropTypes.bool,
  match: PropTypes.object,
  onBreadCrumbLoading: PropTypes.func.isRequired,
  onGetInstance: PropTypes.func.isRequired,
  selectedInstance: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  instances: instancesSelector(),
  router: routerSelector(),
  initSagaLibrary: initSagaLibrarySelector(),
  selectedInstance: selectedInstanceSelector(),
});

export function mapDispatchToProps(dispatch) {
  return {
    onBreadCrumbLoading: breadCrumbSection =>
      dispatch(updateBreadCrumb(breadCrumbSection)),
    onGetInstance: testInstanceId =>
      dispatch(getInstanceAction(testInstanceId)),
  };
}

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withSaga = injectSaga({ key: 'testSelectPage', saga });
const withReducer = injectReducer({ key: 'testSelectPage', reducer });
const withDirectionsReducer = injectReducer({
  key: 'directionsPage',
  reducer: directionsPageReducer,
});

export default compose(
  withReducer,
  withDirectionsReducer,
  withSaga,
  withConnect,
)(withAITracking(reactPlugin, injectIntl(TestReview), 'TestReview'));
